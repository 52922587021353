import React from 'react';
import styled from 'styled-components';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const LtContainer = styled.div`
  width: 100vw;
  max-width: 960px;
  border: 2px solid #dedede;
  border-radius: 10px;
  margin: 40px auto;
  font-family: 'Roboto', sans-serif, ariel;
  font-size: 10px;
`;

const HeadlineContainer = styled.div`
  box-sizing: border-box;
  background: #00c273;
  text-transform: uppercase;
  padding: 10px;
  margin: auto;
  max-width: 980px;
  text-align: center;
  h1 {
    font-size: 28px;
    font-weight: 300;
    margin: 0;
    color: #fff;
  }
  .bold {
    font-size: 48px;
    font-weight: 500;
  }
`;

const LogoContainer = styled.div`
  width: calc(100% - 20px);
  margin: 20px auto 0;
  max-width: 380px;
  img {
    max-width: 100%;
  }
`;

const QuestionsContainer = styled.div`
  box-sizing: border-box;
  background: #dce9f1;
  border: none;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
  max-width: 980px;
  color: #dce9f1;
  padding: 10px 20px 10px;
  margin: auto;
`;

const ThreeItems = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 801px) {
    flex-wrap: wrap;
  }
`;
const ThreeItemsContent = styled.div`
  width: 31%;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  color: #2863a2;
  img {
    width: 36px;
    margin-top: 24px;
  }
  p {
    font-size: 15px;
    color: #000;
  }
  @media (max-width: 801px) {
    width: 100%;
  }
`;
const IconContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #00c273;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;
const LtThankyou = () => (
  <LtContainer>
    <HeadlineContainer>
      <h1>
        <span className="bold"> You've been matched</span>
      </h1>
    </HeadlineContainer>
    <LogoContainer>
      <img
        src="https://s3.amazonaws.com/sc-dba.com/lt-thankyou/images/ltLogo.png"
        alt="Lending Tree"
      />
    </LogoContainer>
    <QuestionsContainer>
      <ThreeItems>
        <ThreeItemsContent>
          <IconContainer>
            <img
              src="https://s3.amazonaws.com/sc-dba.com/lt-thankyou/images/homeIcon.png"
              alt="Home Icon"
            />
          </IconContainer>
          <p>
            Lenders are competing for your business, which means you get the
            most competitive offers. No markup from us.
          </p>
        </ThreeItemsContent>
        <ThreeItemsContent>
          <IconContainer>
            <img
              src="https://s3.amazonaws.com/sc-dba.com/lt-thankyou/images/capitolIcon.png"
              alt="Capitol Icon"
            />
          </IconContainer>
          <p>
            We make loan shopping easy. You can compare offers side by side,
            talk with loan officers to haggle, or in many cases, just apply
            online. Choose the loan that works best for you.
          </p>
        </ThreeItemsContent>
        <ThreeItemsContent>
          <IconContainer>
            <img
              src="https://s3.amazonaws.com/sc-dba.com/lt-thankyou/images/moneyIcon.png"
              alt="Money Icon"
            />
          </IconContainer>
          <p>
            LendingTree is a leading online loan marketplace with one of the
            largest networks of lenders in the nation.
          </p>
        </ThreeItemsContent>
      </ThreeItems>
    </QuestionsContainer>
  </LtContainer>
);

const googleFontLink =
  'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap';

export default withThankyouWrapper(LtThankyou, googleFontLink);
